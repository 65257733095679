.social-buttons {
  text-align: center;
  border-top: 1px solid #EAEAEA;
  margin-top: 25px;
  padding-top: 10px;
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 20px;
}

article a {
  text-decoration: underline;
}

article .articles-list .title {
  margin: 20px auto 5px auto;
}

article .articles-list .authors,
article .articles-list .summary,
article .articles-list .tags {
  margin: 3px auto 3px 35px;
}

article .articles-list .tags {
  font-style: italic;
  color: #555;
  a {
    color: lighten(#008738, 0.2);
  }
}

article .label.season {
  padding: 0 4px;
  font-size: 9px;
  background-color: #b5b5b5;
  font-weight: normal;
  text-decoration: none;
}

article .search-block-left,
article .search-block-right {
  padding: 5px;
}

article .search-block-left {
  overflow: hidden;
}

article .search-block-right {
  float: right;
  width: 70px;
}

article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
  color: #199043;
}

article h1 {
  font-size: 24px;
  line-height: 26px;
}

article h2 {
  font-size: 20px;
  line-height: 22px;
}

article h3 {
  font-size: 18px;
  line-height: 19px;
}

article h4 {
  font-size: 16px;
  line-height: 17px;
}

article h5 {
  font-size: 14px;
  line-height: 15px;
}

article h6 {
  font-size: 10px;
  line-height: 11px;
}

article [itemprop="articleBody"] {
  // Чтоб на узких экранах появлялась полоса прокрутки у параграфов с широкими изображениями, таблицами:
  p
  ,div
  ,table {
    overflow-x: auto;
    overflow-y: hidden;
  }

  p {
    padding-bottom: 0.1em;
  }
}

@media print {
  article h1,
  article h2,
  article h3,
  article h4,
  article h5,
  article h6 {
    text-align: left;
  }
}

/* Для кнопки разворачивания и сворачивания во весь экран */
.full-screen-content {
  background-color: #FFFFFF;
  position: relative;
  margin-top: 3em;
  overflow: auto;

  .full-screen-content-deactivate
  ,&.full-screen-content-activated .full-screen-content-activate
  {
    display: none;
  }

  .full-screen-content-activate
  ,&.full-screen-content-activated .full-screen-content-deactivate {
    display: inline;
  }
}

.full-screen-content-buttons {
  text-align: center;
  /*position: absolute;*/
  /*right: 5px;*/
  opacity: 0.75;
  margin: 0.7em 0;

  button {
    text-align: center;
    line-height: 1.5em;
    border-radius: 3px;
    border: 0 solid;
    background-color: #6a918f;
    color: #000000;
    padding: 3px 9px;

    &:hover {
      background-color: saturate(#6a918f, 35%);
    }
  }
}
